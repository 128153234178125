import * as React from "react"
import {
  Container,
  Center,
  Box,
  Divider,
  Text,
  Heading,
  Link,
  Flex,
  Spacer,
  Image,
  Icon,
} from "@chakra-ui/react"


import { RiTwitterLine } from "@react-icons/all-files/ri/RiTwitterLine";
import { RiMailSendLine } from "@react-icons/all-files/ri/RiMailSendLine";




import logo from "../images/logo_ondark_long.png"

// styles
const pageStyles = {
  color: "#e5eaf4",
  // padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const footerStyles = {
  // position: "sticky"
}



// #4fe1c4





const headingStyles = {
  fontWeight: "bold"
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: "300",
  fontSize: "24px",
  maxWidth: "560px",
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: "16px",
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}


// markup
const IndexPage = () => {
  return (
    <Container bg="#1d2125" h="100%" px={50} py="110px" maxW="xxl" style={pageStyles}>

      <Box w={["100%", "75%", "30%"]} maxW="270px" mb="81px">
        <Image src={logo} alt="Nine Realms Logo" />
      </Box>

      <Box maxW="xxl" overflow="hidden" mb="60px">
        <Heading size="4xl">We bring institutional liquidity to THORChain.</Heading>
      </Box>

      <Box maxW="800px" mb="60px">
        <Text fontSize="2xl">Nine Realms reduces the risks associated with supplying large amounts of asymmetric liquidity on THORChain.</Text>
      </Box>

      <Box maxW="800px">
        <Text fontSize="md">
          Our team of crypto-natives and security experts works with investors and treasuries to intelligently deploy RUNE-paired assets.
          DM&nbsp;
          <Link color="#4fe1c4" href="https://twitter.com/njess" target="_blank">@njess</Link>,&nbsp;
          <Link color="#4fe1c4" href="https://twitter.com/GavinMcDermott" target="_blank">@gavinmcdermott</Link>,
          or the <Link color="#4fe1c4" href="https://twitter.com/ninerealms_cap" target="_blank">@ninerealms_cap</Link> team to learn more.
        </Text>
      </Box>

    </Container>
  )
}

export default IndexPage


{/* <main style={pageStyles}>
  <Box p={8}>
    <Text fontSize="xl">S Hello World</Text>
  </Box>
  <title>Home Page</title>
  <h1 style={headingStyles}>
    Congratulations
    <br />
    <span style={headingAccentStyles}>— you just made a Gatsby site! </span>
    <span role="img" aria-label="Party popper emojis">
      🎉🎉🎉
    </span>
  </h1>
  <p style={paragraphStyles}>
    Edit <code style={codeStyles}>src/pages/index.js</code> to see this page
    update in real-time.{" "}
    <span role="img" aria-label="Sunglasses smiley emoji">
      😎
    </span>
  </p>
</main> */}
{/* <Link color="#4fe1c4" href="https://twitter.com/GavinMcDermott" target="_blank"><Icon as={RiTwitterLine} /></Link> */}
